var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"game-card__item"},[_c('picture',[_c('source',{staticClass:"game-card__image",attrs:{"type":"image/webp","srcset":require(`@/assets/images/gameCards/webp/${_vm.card.src}.webp`) +
        ' 1x, ' +
        require(`@/assets/images/gameCards/webp/${_vm.card.src}@2x.webp`) +
        '2x',"alt":"card-image"}}),_c('img',{staticClass:"game-card__image",attrs:{"src":require(`@/assets/images/gameCards/png/${_vm.card.src}.png`),"srcset":require(`@/assets/images/gameCards/png/${_vm.card.src}.png`) +
        ' 1x, ' +
        require(`@/assets/images/gameCards/png/${_vm.card.src}@2x.png`) +
        '2x',"alt":"card-image"}})]),_c('h3',{staticClass:"game-card__title",domProps:{"innerHTML":_vm._s(_vm.card.title)}}),_c('div',{staticClass:"game-card__desc"},[_vm._v(_vm._s(_vm.card.desc))]),_c('div',{staticClass:"game-card__button"},[_c('Button',{attrs:{"title":"Place Bet","target":true,"url":"https://heyspin.com/sport/?NeoDL=Registration"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }