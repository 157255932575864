<template>
  <div class="game-card__item">
    <picture>
      <source
        type="image/webp"
        :srcset="
          require(`@/assets/images/gameCards/webp/${card.src}.webp`) +
          ' 1x, ' +
          require(`@/assets/images/gameCards/webp/${card.src}@2x.webp`) +
          '2x'
        "
        alt="card-image"
        class="game-card__image"
      />
      <img
        :src="require(`@/assets/images/gameCards/png/${card.src}.png`)"
        :srcset="
          require(`@/assets/images/gameCards/png/${card.src}.png`) +
          ' 1x, ' +
          require(`@/assets/images/gameCards/png/${card.src}@2x.png`) +
          '2x'
        "
        alt="card-image"
        class="game-card__image"
      />
    </picture>
    <h3 class="game-card__title" v-html="card.title"></h3>
    <div class="game-card__desc">{{ card.desc }}</div>
    <div class="game-card__button">
      <Button
        title="Place Bet"
        :target="true"
        url="https://heyspin.com/sport/?NeoDL=Registration"
      />
    </div>
  </div>
</template>

<script>
import Button from "../layout/Button.vue";
export default {
  name: "GameCard",
  components: { Button },
  props: {
    card: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss">
.game-card {
  &__item {
    padding: 52px 35px 70px;
    width: 390px;
    height: 530px;
    border-radius: 6px;
    text-align: center;
    z-index: 0;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: 6px;
    }

    &:before {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background: var(--color-black);
    }

    &:after {
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      top: -1px;
      left: -1px;
      z-index: -2;
      background: var(--color-outlines-inner-border);
    }

    @media (max-width: $size-xl) {
      width: 288px;
      height: 394px;
    }

    @media (max-width: $size-lg) {
      width: 244px;
      height: 344px;
    }

    @media (max-width: $size-md) {
      width: 288px;
      height: 394px;
      margin-bottom: 46px;
    }
  }

  &__image {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__title {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    color: var(--color-white);
    margin-bottom: 14px;
    margin-top: 280px;

    @media (max-width: $size-xl) {
      font-size: 16px;
      margin-top: 190px;
    }

    @media (max-width: $size-lg) {
      margin-top: 155px;
    }

    @media (max-width: $size-md) {
      margin-top: 188px;
      font-size: 17px;
    }
  }

  &__desc {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: var(--color-grey-light);

    @media (max-width: $size-xl) {
      font-size: 12px;
    }
  }

  &__button {
    position: absolute;
    bottom: -44px;

    @media (max-width: $size-xl) {
      bottom: -29px;
    }

    @media (max-width: $size-lg) {
      bottom: -24px;
    }

    @media (max-width: $size-md) {
      bottom: -28px;
    }
  }
}
</style>
