<template>
  <div class="container">
    <div class="game-cards">
      <div class="game-cards__list">
        <GameCardsItem v-for="card in cards" :card="card" :key="card.key" />
      </div>
    </div>
  </div>
</template>

<script>
import GameCardsItem from "./GameCardsItem.vue";

export default {
  name: "GameCards",
  components: { GameCardsItem },
  data() {
    return {
      cards: [
        {
          id: 1,
          title: "BOOST YOUR<br>COMBO WINS",
          desc: "Bet on pre-match Combos & get up to 77% extra winnings in Cash.",
          src: "card1",
        },
        {
          id: 2,
          title: "TAKE CONTROL<br>AND CASH OUT",
          desc: "Take full control of your bet and Cash Out for an early settlement.",
          src: "card2",
        },
        {
          id: 3,
          title: "BUILD IT WITH BET<br>BUILDER",
          desc: "Create the bet you want. Combine two or more markets from one event, and even pair it into a Combo bet",
          src: "card3",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.game-cards {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 80px;

    @media (max-width: $size-md) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }
  }
}
</style>
